.main-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 12px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: none;
  cursor: default;

  &:not(:disabled) {
    cursor: pointer;
  }

  &.primary {
    color: white;
    background: #323538;

    &:not(:disabled):hover {
      background: #46494C;
    }

    &:not(:disabled):active,
    &:not(:disabled).active {
      background: #505356;
    }

    &:disabled {
      background: #F4F5F5;
      border: 1px solid #D9E1E6;
      color: #2C2C2C;

      & .main-button__icon-wrapper {
        & svg path {
          fill: #838383;
        }
      }
    }

    & .main-button__icon-wrapper {
      & svg path {
        fill: white;
      }
    }
  }

  &.secondary {
    color: #405264;
    background: #D0E0EA;

    &:not(:disabled):hover {
      background: #C6D6E0;
    }

    &:not(:disabled):active {
      background: #BECED8;
    }

    &:disabled {
      background: #F4F5F5;
      border: 1px solid #D9E1E6;

      & .main-button__icon-wrapper {
        & svg path {
          fill: #838383;
        }
      }
    }

    & .main-button__icon-wrapper {
      & svg path {
        fill: #405264;
      }
    }
  }

  &.accent {
    color: white;
    background: #D04532;

    &:hover {
      background: #BC311E;
    }

    &:active {
      background: #B22714;
    }
  }
}

.main-button__icon-wrapper {
  display: flex;
  align-items: center;
}

