.home-page__add-object-btn {
  position: relative;
  width: 32px;
  height: 32px;

  &.active {
    background-color: #0078d2;

    &:hover {
      background-color: #0091ff !important;
    }

    &:active {
      background-color: #0a64be !important;
    }
  }
}

.building-types-list {
  width: 240px;
}
