.connection-point__top-row-container {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
  margin-bottom: 12px;
}

.connection-point__second-row-container {
  margin-bottom: 20px;
  margin-left: 32px;
}

.object-panel__dummy-label {
  height: 17px;
  margin-bottom: 2px;
}

.connection-point__container:last-child {
  & .object-panel__separator {
    display: none;
  }
}

.connection-point__props-container {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  //flex: 1 1 268px;
  //max-width: fit-content;
  //min-width: 128px;
  flex: max-content;
}

.connection-point__type-container {
  min-width: 128px;
  max-width: 128px;
}

.connection-point__position-value-container {
  min-width: 100px;
  max-width: 100px;
  align-self: flex-end;
}

.connection-point__delete-btn {
  width: 32px;
  height: 32px;
}

.position-btn {
  &.active {
    & svg rect:nth-child(2),
    & svg path:nth-child(2){
      fill: #ffffff;
    }
    & svg rect:first-child {
      stroke: #8DBEDD;
    }
  }

  &:disabled {
    & svg rect:first-child {
      stroke: #B6B6B6;
    }
    & svg rect:nth-child(2),
    & svg path:nth-child(2){
      fill: #8D8D8D;
    }

    &.active {
      & svg rect:first-child {
        stroke: #8E8E8E;
      }
      & svg rect:nth-child(2),
      & svg path:nth-child(2){
        fill: #5B5B5B;
      }
    }
  }
}

.position-btn:nth-child(2) {
  transform: rotate(90deg);
}

.position-btn:nth-child(3) {
  transform: rotate(-90deg);
}

.position-btn:nth-child(4) {
  transform: rotate(180deg);
  border-radius: 4px 0 0 4px;
}
