.method-window__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.16);
}

.method-window__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 380px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
  transform: translate(-50%, -50%);
}

.method-window__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.method-window__title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.method-window__method-name {
  margin: 18px 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
  text-align: center;
}
