.dropdown__container {
  position: relative;
  width: 100%;
}

.dropdown__value-container {
  position: relative;
  width: 100%;
  height: 32px;
  padding: 6px 8px;
  background-color: white;
  border: 1px solid #D9E1E6;
  border-radius: 4px;

  &:not(:disabled):hover {
    background-color: #FAFAFA;
  }

  &:disabled {
    background-color: #F4F5F5;
    border: 1px solid #D9E1E6;
    border-radius: 4px;
  }
}

.dropdown__value-wrapper {
  position: relative;
  max-width: calc(100% - 12px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.dropdown__value {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2C;

  &.animate:hover {
    -webkit-animation: moveLeft 15s linear 1.5s 1;
  }
}

.dropdown__indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  &.open {
    transform: translateY(-50%) rotate(-180deg);
  }
}

.dropdown__options-list {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: white;
  margin-top: 5px;
  border: 1px solid #D9E1E6;
  border-radius: 4px;
  max-height: 270px;
  overflow: overlay;

  &::-webkit-scrollbar {
    background: transparent;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(237, 237, 237, 1);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 14px;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track-piece
  {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }

  &.open-up {
    bottom: calc(32px + 5px);
    margin-top: 0;
  }

  &.no-display {
    opacity: 0;
  }
}

.dropdown__option {
  display: block;
  padding: 7.5px 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  &:not(:last-child) {
    border-bottom: 1px solid #D9D9D9;
  }

  &:hover {
    background-color: #FAFAFA;
  }

  &:active {
    background-color: #F8F8F8;
  }
}

.dropdown__option-value {
  //width: 100%;
  position: relative;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2C;

  &.animate:hover {
    -webkit-animation: moveLeft 15s linear 1.5s 1; /* Safari 4.0 - 8.0 */
    //animation: moveLeft 5s linear 3s 1 alternate;
  }
}

.dropdown__empty-options {
  margin: 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes moveLeft {
  from {
    margin-left: 0;
  }
  80% {
    margin-left:  -300%;
  }
  to {
    margin-left: 0;
  }
}
