.connections__container {
  margin: 20px 0;
}

.connections__top-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.connections__top-row-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.connections__edit-btn {
  width: 32px;
  height: 32px;
}

.connection-point__container {
  margin-top: 20px;
}

.connection-point__top-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.connection-point__index {
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50%;
  background-color: #4454e5;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}

.connection-point__type {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #002033;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.connection-point__row {
  margin-top: 8px;
}

.connection-point__connection-row {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.connection-point__connection-row-dropdown {
  flex: 1;
  width: 80%;
}

.connection-point__show-btn {
  width: 32px;
  height: 32px;

  & svg path {
    fill: none !important;
  }
}

.connection-point__connection-row-types {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.connection-point__connection-method {
  min-width: 100px;
  flex: 1;
  width: 40%;
}

.connection-point__connection-type {
  position: relative;
  flex: 1;
  min-width: 180px;
  width: 60%;
}
