.specification__top-row-title {
  margin: 20px 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.specification__item,
.property__item {
  width: 100%;
  margin-bottom: 20px;
}

.shape-row {
  margin-bottom: 20px;
}

.shape-row__container {
  margin-top: 20px;
}
