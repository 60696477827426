.input {
  width: 100%;
  padding: 6px 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2C2C2C;
  background-color: white;
  border: 1px solid #D9E1E6;
  border-radius: 4px;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:not(:disabled):hover {
    background-color: #FAFAFA;
  }

  &:focus {
    border: 1px solid #0078D2
  }

  &:disabled {
    background-color: #F4F5F5;
    border: 1px solid #D9E1E6;
    border-radius: 4px;
  }

  &.with-unit {
    padding-right: 24px;
  }
}

