.layout__header {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  //padding: 0 16px;
  background-color: #F4F8FA;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.layout__left-header-content {
  width: 52%;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2C2C2C;
  white-space: nowrap;
}

.layout__left-header-content-input-wrapper {
  width: 276px;
}

.layout__left-header-content-dropdown-wrapper {
  width: 200px;
  margin-left: 8px;
}

.layout__left-header-content-status-wrapper {
  margin-left: 8px;
}

.layout__buttons {
  display: flex;
  position: relative;
  gap: 20px;
  margin-right: 16px;
}

.layout__left-header-content-button-wrapper {
  margin-left: 32px;
  width: 115px;
  z-index: 100;
}

.actions-button__points-icon {
  margin-right: 10px;
}

.dropdown__options-list.project {
  width: 382px;
}

.dropdown__options-list.solution {
  width: 220px;
}

.theme-btn {
  width: 32px;
  height: 32px;
  display: flex;
  position: absolute;
  right: 504px;
}

.main-button.layout-btn {
  padding: 6px 12px;
}

.solution-status {
  width: 100px;
  padding: 3px 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.published {
    background-color: #3D9446;
  }

  &.draft {
    background-color: #865BE2;
  }
}
