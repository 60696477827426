.checkbox-row {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  /*&.start-aligned {
    align-items: flex-start;
  }*/

  &:disabled {
    .checkbox-icon {
      background-color: #EEEEEE;
    }

    .checked-icon path {
      fill: #838383;
    }
  }
}

.checkbox-label {

}

.checkbox-icon {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #838383;
  border-radius: 4px;

  &:hover {
    background-color: #F6F6F6;
  }

  & .checked-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.checkbox-content {
  display: block;
  text-align: left;
  flex: 1;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #838383;
}
