.object-panel__empty-message {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #838383;
}

.object-panel__type-row {
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}

.object-panel__angle-row {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.object-panel__coordinates-row {
  display: flex;
  gap: 12px;
  margin: 20px 0;
}

.object-panel__label {
  margin-bottom: 2px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
  max-width: 95%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.object-panel__short-input-wrapper {
  position: relative;
  width: 100px;
}

.object-panel__title-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin: 20px 0;
}

.object-panel__size-row {
  display: flex;
  width: 100%;
  gap: 12px;
  margin: 20px 0;
}

.object-panel__size-wrapper {
  width: 30%;
}

.object-panel__fix-position-row {
  margin-bottom: 20px;
}

.object-panel__size-row-size {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.title-row__number-wrapper {
  width: 20%;
}

.title-row__title-wrapper {
  width: 80%;
}

.object-panel__separator {
  border-bottom: 1px solid #d9d9d9;
}

.object-panel__input-wrapper {
  position: relative;
}

.coordinates-row__unit {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #838383;
}
