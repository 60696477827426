.home-page__container {
  //padding-top: 56px;
}

.home-page__content {
  display: flex;
  padding: 88px 64px 45px 64px;
  background-color: #eff4f6;
  column-gap: 40px;
}

.home-page__side-panel {
  max-width: 19.5%;
  min-width: 19.5%;
  background-color: white;
  border-radius: 4px;

  &.left {
    //padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    background-color: transparent;
  }

  &.right {
    padding: 20px;
    max-height: calc(100vh - 56px - 32px - 45px);
    overflow: overlay;
    &::-webkit-scrollbar {
      background: transparent;
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(237, 237, 237, 1);
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 14px;
      min-height: 30px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 6px;
    }
  }
}

.home-page__objects-list {
  padding: 20px 0;
  background-color: white;
  flex: 1;
  max-height: calc(100vh - 130px - 40px - 56px - 32px - 45px);
  overflow: overlay;
  border-radius: 4px;

  &.solution-property {
    max-height: calc(100vh - 56px - 32px - 45px);
    padding: 20px;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(237, 237, 237, 1);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 14px;
    min-height: 30px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    -webkit-border-radius: 6px;
  }
}

.home-page__algorithm {
  padding: 20px;
  height: 130px;
  background-color: white;
  border-radius: 4px;
}

.home-page__algorithm-controls {
  position: relative;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  max-width: 100%;
}

.home-page__algorithm-select {
  position: relative;
  width: 60%;
}

.home-page__graphic-panel {
  position: relative;
  width: 61%;
}

.home-page__objects-list-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  &:not(.object-panel-top-row) {
    padding: 0 20px;
  }

  &.object-panel-top-row {
    margin-bottom: 20px;
  }
}

.solution-property .home-page__objects-list-top-row {
  padding: 0;
  margin-bottom: 20px;
}

.home-page__objects-list-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;

  &.algorithm-title {
    margin-bottom: 20px;
  }
}

.home-page__object-item {
  display: flex;
  align-items: center;
  height: 58px;
  padding: 0 20px;
  border-bottom: 1px solid #d9e1e6;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  &:active {
    background-color: #f8f8f8;
  }

  &.selected {
    background-color: #f0f3fb;
  }
}

.icon-buttons-row-btn {
  padding: 8px;
  height: 32px;
  background-color: #D0E0EA;

  &:hover {
    background-color: #C6D6E0;
  }

  &:active {
    background-color: #BECED8;
  }

  &.active {
    background-color: #0078D2;

    &:hover {
      background-color: #0091FF;
    }

    &:active {
      background-color: #0A64BE;
    }
  }

  &:disabled {
    background-color: #F4F5F5;
    & svg rect {
      stroke: #B6B6B6;
    }
    & svg path {
      fill: #B6B6B6;
    }

    &.active {
      background-color: #E2E2E2;
      & svg rect {
        stroke: #8E8E8E;
      }
      & svg path {
        fill: #8E8E8E;
      }
    }
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
